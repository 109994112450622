<template>
  <div>
    <div
      v-if="searchrow"
      class="alert alert alert-white alert-shadow fade show alert-light"
    >
      <div class="search row">
        <div class="col-2">
          <label>Mijoz</label>
          <b-form-input
            v-debounce:400ms="myFn"
            v-model="client_name"
            placeholder="Mijoz"
          ></b-form-input>
        </div>
        <div class="col-2">
          <label>Boshlanish</label>

          <v-dialog
            ref="dialog"
            v-model="start_menu"
            :return-value.sync="start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                v-model="start_date"
                type="text"
                v-bind="attrs"
                v-on="on"
                @input="myFn"
                class="form-control"
                placeholder="Boshlanish"
              />
            </template>
            <v-date-picker v-model="start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="start_menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    $refs.dialog.save(start_date)
                    myFn()
                  }
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="col-2">
          <label>Tugash</label>

          <v-dialog
            ref="dialog2"
            v-model="end_menu"
            :return-value.sync="end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                v-model="end_date"
                type="text"
                v-bind="attrs"
                v-on="on"
                @input="myFn"
                class="form-control"
                placeholder="Tugash"
              />
            </template>
            <v-date-picker v-model="end_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="end_menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    $refs.dialog2.save(end_date)
                    myFn()
                  }
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="col-3">
          <label>Shahardan</label>

          <treeselect
            :options="options"
            :clearable="clearable"
            :clearOnSelect="clearOnSelect"
            placeholder="Shahardan"
            @input="myFn"
            v-model="city"
          />
        </div>
        <div class="col-3">
          <label>Shaharga</label>

          <treeselect
            :options="options"
            :clearable="clearable"
            :clearOnSelect="clearOnSelect"
            placeholder="Shaharga"
            @input="myFn"
            v-model="secondcity"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Buyurtmalar</h3>
            </div>
            <div class="card-button">
              <b-button @click="searchactive" variant="primary mr-2"
                >Izlash</b-button
              >
              <b-button
                @click="getStatus(item.status)"
                :class="`${item.status_space}`"
                v-for="item in goodstatus"
                :key="item.id"
                :variant="`${item.status_color}`"
              >
                {{ item.status_name }}
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="getData"
              no-data-text="Malumot mavjud emas"
              item-key="name"
              loading-text="Malumot yuklanmoqda..."
              :page.sync="page"
              :items-per-page="10"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.index`]="{ item }">
                <div class="text-black">
                  {{
                    getData
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(item.id) + 1
                  }}
                </div>
              </template>
              <template v-slot:[`item.zayavka_owner`]="{ item }">
                <div class="text-black">
                  {{ item.zayavka_owner }}
                </div>
              </template>
              <template v-slot:[`item.contract`]="{ item }">
                <div class="text-black">
                  {{ item.contract }}
                </div>
              </template>
              <template v-slot:[`item.deliver`]="{ item }">
                <div class="text-black" v-if="item.deliver == 'POS'">
                  Postavshik
                </div>
                <div class="text-black" v-else>BTS</div>
              </template>

              <template v-slot:[`item.price_with_nds`]="{ item }">
                <div class="text-black">
                  {{ item.price_with_nds }}
                </div>
              </template>
              <template v-slot:[`item.truck`]="{ item }">
                <div v-if="item.truck">
                  <div v-if="item.truck !== null">
                    {{ item.truck.reg_number_region }}
                    {{ item.truck.reg_number }}
                  </div>
                </div>
                <div v-else>
                  {{ item.postavshik_truck_info }}
                </div>
              </template>
              <template v-slot:[`item.start_date_new`]="{ item }" to="/">
                <div class="text-black">
                  {{ item.start_date_new | formatDate }}
                </div>
              </template>
              <template v-slot:[`item.direction`]="{ item }">
                <span
                  v-for="(city, index) in item.direction_cities"
                  :key="city.id"
                >
                  {{ city.city_name.name }}
                  <i v-if="index + 1 !== item.direction_cities.length">-</i>
                </span>
              </template>
              <template v-slot:[`item.zayavka_status_name`]="{ item }">
                <div :class="item.status_style" class="status__name">
                  {{ item.zayavka_status_name }}
                </div>
              </template>
            </v-data-table>
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="Math.ceil(getData.length / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      clearable: true,
      searchrow: false,
      clearOnSelect: true,
      city: null,
      start_date2: '',

      secondcity: null,
      client_name: '',
      options: [
        {
          id: 1,
          label: 'Toshkent shahar'
        },
        {
          id: 2,
          label: 'Samarqand'
        },
        {
          id: 3,
          label: 'Buxoro'
        },
        {
          id: 4,
          label: 'Andijon'
        },
        {
          id: 5,
          label: 'Fargʻona'
        },
        {
          id: 6,
          label: 'Namangan'
        },
        {
          id: 7,
          label: 'Xorazm'
        },
        {
          id: 8,
          label: 'Surxondaryo'
        },
        {
          id: 9,
          label: 'Sirdaryo'
        },
        {
          id: 10,
          label: 'Jizzax'
        },
        {
          id: 11,
          label: 'Navoiy'
        },
        {
          id: 12,
          label: 'Qashqadaryo'
        },
        {
          id: 13,
          label: 'Nukus'
        }
      ],
      page: 1,
      sorted: '',
      start_date: '',
      end_date: '',
      start_menu: false,
      end_menu: false,
      goodstatus: [
        {
          status_name: 'Buyurtmachida',
          status_color: 'danger',
          status: 'OTB'
        },
        {
          status_name: "Yo'lda",
          status_color: 'warning',
          status_space: 'ml-2',
          status: 'OTW'
        },
        {
          status_name: 'Yetkazilgan',
          status_color: 'success',
          status_space: 'ml-2',
          status: 'DEL'
        }
      ],
      headers: [
        { text: '#', value: 'index' },
        { text: 'Mijoz', value: 'zayavka_owner' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Mashina raqami', value: 'truck' },
        { text: 'Boshlanish', value: 'start_date_new' },
        { text: "Yo'nalish", value: 'direction' },
        { text: 'Status', value: 'zayavka_status_name' }
      ]
    }
  },
  components: {
    Treeselect
  },
  created() {
    this.$store.dispatch('getDriverZayavkas')
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Buyurtmalar' }])
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getData() {
      var data = this.$store.state.driverStore.driverZayavkas
      if (this.sorted) {
        return data.filter((x) => x.zayavka_status == this.sorted)
      } else {
        return data
      }
    }
  },
  methods: {
    getStatus(val) {
      this.sorted = val
    },
    searchactive() {
      this.searchrow = !this.searchrow
    },
    myFn() {
      const data = {}
      data.client_name = this.client_name
      data.from_date = this.start_date
      data.to_date = this.end_date
      data.from_city = this.city
      data.to_city = this.secondcity
      for (let i in data) {
        if (!data[i]) {
          delete data[i]
        }
      }

      this.$store.dispatch('getDriverZayavkasFilter', data)
    }
  }
}
</script>

<style scoped>
.text-black {
  color: #000;
}
.click {
  cursor: pointer;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
